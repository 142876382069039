<template>
    <section class="c-summary">
        <section class="c-summary-hero c-summary-hero--intro">
            <div class="c-summary-hero__img u-cover" v-if="!loading && style.image" :style="{ backgroundImage: 'url(' + style.image + ')' }"></div>

            <div class="c-summary-hero__content">
                <h1>Mijn nieuwe bedrijfspand</h1>
                <h2 class="gamma">Bekijk hieronder een samenvatting van alle gemaakte keuzes.</h2>
            </div>
        </section>

        <section class="c-summary-style">
            <div class="c-summary-style__img u-cover" v-if="!loading && Object.values(style.images).length && Object.values(style.images.detail).length" :style="{ backgroundImage: 'url(' + style.images.detail[0].path + ')' }"></div>

            <div class="c-summary-style__content">
                <h2 class="alpha">Stijl:<br/> <span class="u-text-gray-dark">{{ style.name }}</span> </h2>

                <router-link class="[ c-btn c-btn--outline-primary ] c-summary-btn" to="/">Wijzigen</router-link>
            </div>
        </section>

        <section class="c-summary-content">
            <div class="o-container o-container--small">

                <template v-if="loading">Bezig met laden...</template>

                <template v-else>
                    <template v-if="style.descriptions.length > 0">
                        <h4>{{ style.descriptions[0].header }}</h4>
                        <p v-html="style.descriptions[0].description"></p>
                    </template>

                    <template v-else-if="style.descriptions.length === 0">
                        <inline-alert type="warning">Geen teksten gevonden voor de stijl: {{ style.name }}</inline-alert>
                    </template>

                    <template v-else>
                        <inline-alert type="warning">Er ging iets fout met het laden van de details</inline-alert>
                    </template>
                </template>

            </div>
        </section>

        <section class="c-summary-hero" v-if="!loading">
            <div class="c-summary-hero__img u-cover" v-if="style.images.slider" :style="{ backgroundImage: 'url(' + style.images.slider[0].path + ')' }"></div>

            <div class="c-summary-hero__content">
                <div class="o-container">
                    <template v-if="roles">
                        <h3>U gaat uw pand gebruiken als:</h3>
                        <h2 class="alpha">{{ roles }}</h2>

                        <router-link class="[ c-btn c-btn--outline-tint ] c-summary-btn" to="/roles">Wijzigen</router-link>
                    </template>

                    <template v-else>
                        <inline-alert type="warning">Er is nog geen functie gekozen</inline-alert>
                        <router-link class="[ c-btn c-btn--outline-tint ] c-summary-btn" to="/roles">Wijzigen</router-link>
                    </template>
                </div>
            </div>
        </section>

        <section class="c-summary-sizes c-summary-content" v-if="surfaceArea > 0">
            <div class="o-container">

                <template v-if="sizes.length">
                    <h2 class="alpha">Afmetingen</h2>
                    <h3>Dit zijn de afmetingen van mijn bedrijfspand.</h3>

                    <div class="c-summary-sizes__list">
                        <div class="c-summary-sizes__item">
                            <span class="c-summary-sizes__item-label">Totale lengte</span>
                            <span class="c-summary-sizes__item-value">{{ currentLength }}m</span>
                        </div>

                        <div class="c-summary-sizes__item">
                            <span class="c-summary-sizes__item-label">Totale breedte</span>
                            <span class="c-summary-sizes__item-value">{{ currentWidth }}m</span>
                        </div>

                        <div class="c-summary-sizes__item">
                            <span class="c-summary-sizes__item-label">Totale oppervlakte</span>
                            <span class="c-summary-sizes__item-value">{{ surfaceArea }} m2</span>
                        </div>

                        <div class="c-summary-sizes__item">
                            <span class="c-summary-sizes__item-label">Pand hoogte</span>
                            <span class="c-summary-sizes__item-value">{{ height }}m</span>
                        </div>

                        <div class="c-summary-sizes__item" v-for="size in sizes" :key="'size-' + size.id">
                            <span class="c-summary-sizes__item-label">Oppervlakte {{ size.name }}</span>
                            <span class="c-summary-sizes__item-value">{{ Math.round(size.quantity) }} m2</span>
                        </div>
                    </div>

                    <router-link class="[ c-btn c-btn--outline-primary ] c-summary-btn" to="/sizes">Wijzigen</router-link>
                </template>

                <template v-else>
                    <inline-alert type="warning">Er zijn nog een afmetingen gekozen</inline-alert>
                    <router-link class="[ c-btn c-btn--outline-primary ] c-summary-btn" to="/sizes">Wijzigen</router-link>
                </template>
            </div>
        </section>

        <section class="c-summary-exterior c-summary-content">
            <div class="o-container">

                <template v-if="exteriors.length">
                    <div class="c-summary-exterior__row">
                        <div class="c-summary-exterior__content">
                            <h2 class="alpha">Exterieur</h2>
                            <h3>Dit zijn de benodigde opties voor mijn bedrijfspand.</h3>

                            <router-link class="[ c-btn c-btn--outline-primary ] c-summary-btn || u-hidden-md-down" to="/exterior">Wijzigen</router-link>
                        </div>

                        <div class="c-summary-exterior__list">
                            <div class="c-summary-exterior__item" v-for="exterior in exteriors" :key="'exterior-' + exterior.id">
                                <span class="c-summary-exterior__item-label">{{ !exterior.is_toggle ? 'Aantal ' + exterior.name.toLowerCase() : exterior.name }}</span>
                                <span class="c-summary-exterior__item-value" v-if="exterior.is_toggle">{{ exterior.quantity === 1 ? 'Ja' : 'Nee' }}</span>
                                <span class="c-summary-exterior__item-value" v-else>{{ exterior.quantity }}</span>
                            </div>
                        </div>

                        <div class="c-summary-exterior__content || u-hidden-lg-up">
                            <router-link class="[ c-btn c-btn--outline-primary ] c-summary-btn" to="/exterior">Wijzigen</router-link>
                        </div>
                    </div>
                </template>

                <template v-else>
                    <inline-alert type="warning">Er is nog geen interieur gekozen</inline-alert>
                    <router-link class="[ c-btn c-btn--outline-primary ] c-summary-btn" to="/exterior">Wijzigen</router-link>
                </template>

            </div>
        </section>

        <section class="c-summary-hero" v-if="interior">
            <div class="c-summary-hero__img u-cover" :style="{ backgroundImage: 'url(' + interior.image + ')' }"></div>

            <div class="c-summary-hero__content">
                <div class="o-container">
                    <h3>Interieur</h3>
                    <h2 class="alpha">Mijn bedrijfspand krijgt een {{ interior.name }} interieur.</h2>

                    <router-link class="[ c-btn c-btn--outline-tint ] c-summary-btn" to="/interior">Wijzigen</router-link>
                </div>
            </div>
        </section>

        <!--<section class="c-summary-showcase" v-if="style.images.other">
            <div class="c-summary-showcase__img c-summary-showcase__img&#45;&#45;big u-cover" :style="{ backgroundImage: 'url(' + style.images.other[0].path + ')' }"></div>
            <div class="c-summary-showcase__img c-summary-showcase__img&#45;&#45;small-1 u-cover" :style="{ backgroundImage: 'url(' + style.images.other[0].path + ')' }"></div>
            <div class="c-summary-showcase__img c-summary-showcase__img&#45;&#45;medium-1 u-cover" :style="{ backgroundImage: 'url(' + style.images.other[0].path + ')' }"></div>
            <div class="c-summary-showcase__img c-summary-showcase__img&#45;&#45;medium-2 u-cover" :style="{ backgroundImage: 'url(' + style.images.other[0].path + ')' }"></div>
            <div class="c-summary-showcase__img c-summary-showcase__img&#45;&#45;small-2 u-cover" :style="{ backgroundImage: 'url(' + style.images.other[0].path + ')' }"></div>
        </section>-->

        <section class="c-summary-installation c-summary-content">
            <div class="o-container">

                <template v-if="installation">
                    <div class="c-summary-installation__row">
                        <div class="c-summary-installation__content">
                            <h2 class="alpha">Installaties</h2>
                            <h3>Mijn bedrijfspand krijgt een {{ installation.name }} installatiepakket</h3>

                            <router-link class="[ c-btn c-btn--outline-primary ] c-summary-btn" to="/installation">Wijzigen</router-link>
                        </div>

                        <div class="c-summary-installation__list">
                            <div class="c-summary-installation__item" v-for="option in installation.options" :key="'option-' + option.id">
                                <span class="c-summary-installation__item-label">{{ option.name }}</span>

                                <!--<svg v-if="option.connected" xmlns="http://www.w3.org/2000/svg" width="16.087" height="11.42" viewBox="0 0 16.087 11.42">
                                    <path d="M724.605,374.266l5.081,5.081,9.006-9.006" transform="translate(-723.605 -369.342)" fill="none" stroke="#16c131" stroke-linecap="round" stroke-width="2"></path>
                                </svg>

                                <svg v-if="!option.connected" xmlns="http://www.w3.org/2000/svg" width="11.107" height="11.108" viewBox="0 0 11.107 11.108">
                                    <g fill="none" stroke="#b1051d" stroke-linecap="round" stroke-width="2">
                                        <path d="M10.107 1.001L1 10.109"></path>
                                        <path d="M1 1l9.107 9.107"></path>
                                    </g>
                                </svg>-->
                            </div>
                        </div>
                    </div>
                </template>

                <template v-else>
                    <inline-alert type="warning">Er is nog geen installatie gekozen</inline-alert>
                    <router-link class="[ c-btn c-btn--outline-primary ] c-summary-btn" to="/installation">Wijzigen</router-link>
                </template>

            </div>
        </section>

        <section class="c-summary-save c-summary-content">
            <div class="o-container">
                <div class="c-summary-save__row">

                    <div class="c-summary-save__form-container">

                        <template v-if="errors.length === 0">
                            <save-configuration/>
                        </template>

                        <template v-else>
                            <inline-alert type="error">Kan configuratie niet opslaan want {{ computedErrors }} niet gekozen</inline-alert>
                        </template>
                    </div>

                </div>
            </div>
        </section>
    </section>
</template>

<script>
    import { mapGetters } from 'vuex';
    import InlineAlert from '../components/InlineAlert.vue';
    import SaveConfiguration from '../components/SaveConfiguration.vue';
    import { detailsService } from '../services';

    export default {
        components: { InlineAlert, SaveConfiguration },
        data () {
            return {
                loading: true,
            };
        },
        computed: {
            ...mapGetters('summary', {
                summary: 'getSummaryByIndex',
                surfaceArea: 'getSurfaceArea',
                errors: 'getErrors',
            }),
            currentLength () {
                const { length } = this.summary('style').find((style) => style);
                const size = this.summary('size').find((item) => item.id === 'length');
                return size ? size.quantity : length.min;
            },
            currentWidth () {
                const { width } = this.summary('style').find((style) => style);
                const size = this.summary('size').find((item) => item.id === 'width');
                return size ? size.quantity : width.min;
            },
            height () {
                const { height } = this.summary('style').find((style) => style);
                return height;
            },
            computedErrors () {
                if (this.errors.length > 0)
                {
                    const { errors: errors1 } = this;
                    const errors = errors1.reverse();

                    if (errors.length === 1)
                    {
                        return `${errors} is `;
                    }

                    if (errors.length > 1)
                    {
                        errors.splice(errors.length - 1, 0, 'en');
                    }

                    if (errors.length > 3)
                    {
                        return `${errors.join(', ').replace(', en,', ' en')} zijn`;
                    }

                    return `${errors.join(' ')} zijn`;
                }

                return false;
            },
            style () {
                const style = this.summary('style').find((item) => item.id);

                return {
                    ...style,
                    images: [],
                    descriptions: [],
                };
            },
            roles () {
                const roles = this.summary('role').map((item) => item.name.toLowerCase());

                if (roles.length)
                {
                    if (roles.length >= 2)
                    {
                        roles.splice(roles.length - 1, 0, 'en');
                    }

                    if (roles.length >= 3)
                    {
                        return roles.join(', ').replace(', en,', ' en');
                    }

                    return roles.join(' ');
                }

                return false;
            },
            sizes () {
                return this.summary('role');
            },
            exteriors () {
                return this.summary('exterior');
            },
            interior () {
                return this.summary('interior').find((item) => item.id);
            },
            installation () {
                return this.summary('installation').find((item) => item.id);
            },
        },
        async created () {
            // get the details
            await this.getStyleDetails();

            // reset loading
            this.loading = false;

            // empty previous errors
            this.$store.commit('summary/resetErrors');

            // check for errors
            if (this.summary('installation').length === 0) this.$store.commit('summary/pushError', 'installatie');
            // if (this.summary('interior').length === 0) this.$store.commit('summary/pushError', 'interieur');
            if (this.summary('exterior').length === 0) this.$store.commit('summary/pushError', 'exterieur');
            if (this.surfaceArea === 0) this.$store.commit('summary/pushError', 'afmetingen');
            if (this.summary('role').length === 0) this.$store.commit('summary/pushError', 'functie');
        },
        methods: {
            getStyleDetails () {
                // get style details
                if (this.style.id)
                {
                    return detailsService
                        .getById('styles', this.style.id)
                        .then((result) => {
                            this.style.descriptions = result.descriptions;
                            this.style.images = result.images;
                        }).catch(() => {
                            this.loading = false;
                        });
                }

                return false;
            },
        },
        beforeRouteEnter (to, from, next) {
            next((vm) => {
                // check if style exists
                if (vm.$store.state.summary.style.length === 0)
                {
                    next('/');
                }
                else
                {
                    next();
                }
            });
        },
    };
</script>

<style lang="scss" scoped>
    @import "../assets/scss/essentials";

    .c-summary {
        margin-top: rem(-50);
        margin-bottom: rem(-50);
    }

    .c-summary-hero {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
        height: calc(100vh - #{rem(80)} - #{rem(120)});
        position: relative;

        &:before {
            @include absolute(top 25% left 0 right 0 bottom 0);
            @include gradient-y(rgba(black, .0), rgba(black, .8));
            content: "";
            display: block;
            z-index: 0;
        }

        &__img {
            grid-column: 1 / -1;
            grid-row: 1 / -1;
            -ms-grid-row-span: 2;
        }

        &__content {
            grid-row: 2;
            grid-column: 1;
            color: $white;
            padding-bottom: rem(30);
            position: relative;
            z-index: 1;

            @include media-breakpoint-up(sm) {
                padding-bottom: rem(60);
            }

            @include media-breakpoint-up(xl) {
                padding-bottom: 15vh;
            }

            .c-summary-hero--intro & {
                align-self: center;
                justify-content: center;
                color: $white;
                text-align: center;
            }

            h1,
            .alpha {
                color: inherit;
            }
        }
    }

    .c-summary-content {
        background-color: $white;
        padding-bottom: rem(50);
        padding-top: rem(50);

        @include media-breakpoint-up(sm) {
            padding-bottom: rem(100);
            padding-top: rem(100);
        }
    }

    .c-summary-style {
        display: grid;
        grid-template-rows: 1fr 1fr;

        @include media-breakpoint-up(sm) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto;
        }

        &__img {
            grid-row: 1;
            -ms-grid-column: 1;
        }

        &__content {
            padding: 15% 20%;
            grid-row: 2;

            @include media-breakpoint-up(sm) {
                grid-row: 1;
                -ms-grid-column: 2;
            }

            @include media-breakpoint-up(lg) {
                padding-bottom: rem(120);
                padding-top: rem(120);
            }
        }
    }

    .c-summary-sizes {
        background-color: $white;
        text-align: center;

        &__list {
            display: grid;
            grid-gap: rem(15);
            margin-top: rem(50);

            @include media-breakpoint-up(sm) {
                grid-template-columns: repeat(2, 1fr);
            }

            @include media-breakpoint-up(md) {
                grid-template-columns: repeat(3, 1fr);
            }
        }

        &__item {
            padding: rem(30);
            border: rem(1) solid $gray-light;

            &-label,
            &-value {
                line-height: 1.3;
            }

            &-label {
                color: $brand-primary;
                display: block;
                font-weight: $font-weight-black;
                text-transform: uppercase;
            }

            &-value {
                @include fluid-type(24px, 34px);
                font-weight: $font-weight-light;
            }

            // IE

            $grid: 3;

            @for $i from 1 through $grid {
                &:nth-child(#{$grid}n + #{$i}) {
                    -ms-grid-column: $i;
                }
            }

            &:nth-child(-n + #{$grid}) {
                -ms-grid-row: 1;
            }

            &:nth-child(#{$grid}n + 1):nth-last-child(-n + #{$grid}),
            &:nth-child(#{$grid}n + 1):nth-last-child(-n + #{$grid}) ~ & {
                -ms-grid-row: 2;
            }
        }
    }

    .c-summary-exterior {
        background-color: $gray-lighter;

        &__content {
            -ms-grid-column: 1;

            @include media-breakpoint-down(md) {
                text-align: center;
            }
        }

        &__row {
            @include media-breakpoint-up(sm) {
                display: grid;
                grid-gap: rem(30);
            }

            @include media-breakpoint-up(lg) {
                grid-template-columns: 1fr 1.5fr;
                grid-gap: 10%;
            }

            @include media-breakpoint-up(xl) {
                grid-template-columns: 25% 1fr;
            }
        }

        &__list {
            display: grid;
            grid-gap: rem(15);
            -ms-grid-column: 2;

            @include media-breakpoint-down(xsl) {
                margin-top: rem(40);
            }

            @include media-breakpoint-up(sm) {
                grid-template-columns: repeat(2, 1fr);
            }
        }

        &__item {
            align-items: center;
            padding: rem(20) rem(40);
            border: rem(1) solid $gray-light;
            display: flex;
            justify-content: space-between;

            &-value {
                @include fluid-type(24px, 34px);
                color: $brand-primary;
                display: block;
                font-weight: $font-weight-black;
            }

            // IE

            $grid: 2;

            @for $i from 1 through $grid {
                &:nth-child(#{$grid}n + #{$i}) {
                    -ms-grid-column: $i;
                }
            }

            &:nth-child(1),
            &:nth-child(2) { -ms-grid-row: 1; }

            &:nth-child(3),
            &:nth-child(4) { -ms-grid-row: 2; }

            &:nth-child(5),
            &:nth-child(6) { -ms-grid-row: 3; }

            &:nth-child(7),
            &:nth-child(8) { -ms-grid-row: 4; }
        }
    }

    .c-summary-showcase {
        border: rem(5) solid $white;
        display: grid;
        grid-gap: rem(5);
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-template-areas: "showcase-big showcase-big showcase-small-1 showcase-medium-1"
                             "showcase-big showcase-big showcase-medium-2 showcase-medium-1"
                             "showcase-big showcase-big showcase-medium-2 showcase-small-2";
        height: calc(100vh - #{rem(80)} - #{rem(120)});

        &__img {
            &--big {
                grid-area: showcase-big;
            }

            &--medium-1 {
                grid-area: showcase-medium-1;
            }

            &--medium-2 {
                grid-area: showcase-medium-2;
            }

            &--small-1 {
                grid-area: showcase-small-1;
            }

            &--small-2 {
                grid-area: showcase-small-2;
            }
        }
    }

    .c-summary-installation {
        &__row {
            @include media-breakpoint-up(sm) {
                display: grid;
                grid-gap: 10%;
                grid-template-columns: 1fr 1fr;
            }

            @include media-breakpoint-up(md) {
                grid-template-columns: 33% 1fr;
            }

            @include media-breakpoint-up(xl) {
                grid-template-columns: 25% 1fr;
            }
        }

        &__content {
            -ms-grid-column: 1;
        }

        &__list {
            display: grid;
            grid-gap: rem(15);
            -ms-grid-column: 2;

            @include media-breakpoint-down(xsl) {
                margin-top: rem(40);
            }

            @include media-breakpoint-up(md) {
                grid-template-columns: repeat(2, 1fr);
            }
        }

        &__item {
            align-items: center;
            padding: rem(20) rem(40);
            border: rem(1) solid $gray-light;
            display: flex;
            justify-content: space-between;

            &-label {

            }

            &-value {
                @include fluid-type(24px, 34px);
                color: $brand-primary;
                display: block;
                font-weight: $font-weight-black;
                text-transform: uppercase;
            }

            // IE

            $grid: 2;

            @for $i from 1 through $grid {
                &:nth-child(#{$grid}n + #{$i}) {
                    -ms-grid-column: $i;
                }
            }

            &:nth-child(1),
            &:nth-child(2) { -ms-grid-row: 1; }

            &:nth-child(3),
            &:nth-child(4) { -ms-grid-row: 2; }

            &:nth-child(5),
            &:nth-child(6) { -ms-grid-row: 3; }

            &:nth-child(7),
            &:nth-child(8) { -ms-grid-row: 4; }
        }
    }

    .c-summary-save {
        background-color: $gray-lighter;

        &__row {
            display: grid;

            @include media-breakpoint-up(md) {
                grid-gap: 2.5%;
                grid-template-columns: 2.5% 1fr 2.5%;
            }

            @include media-breakpoint-up(lg) {
                grid-gap: 10%;
                grid-template-columns: 10% 1fr 10%;
            }
        }

        &__content {
            margin-top: 10%;
        }

        &__form-container {
            background-color: $white;

            @include media-breakpoint-up(md) {
                grid-column: 2;
            }
        }
    }

    .c-summary-btn {
        display: inline-block;
        margin-top: rem(30);

        @include media-breakpoint-up(sm) {
            margin-top: rem(50);
        }

        &--reset {
            margin-top: 0;
        }
    }

</style>
