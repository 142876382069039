<template>
    <form v-on:submit.prevent="handleSubmit" :class="'form' + (theme !== '' ? ' form--' + theme : '')" novalidate>
        <div class="form-fieldset">
            <input type="hidden" name="configuration" v-model="configurationJSON" readonly />
            <input type="hidden" name="style" v-model="style.name" readonly />
            <input type="hidden" name="id" v-model="configuration.current.id" readonly />
            <input type="hidden" name="price" v-model="total" readonly />

            <div class="form-group" :class="{ 'has-error': submitted && $v.form.name.$error }">
                <label class="form-label" for="save-name">Omschrijving</label>
                <input type="text" v-model="form.name" id="save-name" name="name" class="form-control form-control--light" placeholder="Vul hier uw omschrijving in" />
                <div v-if="submitted && $v.form.name.$error" class="form-validation">
                    <span v-if="!$v.form.name.required">Omschrijving is verplicht</span>
                </div>
            </div>
        </div>

        <button type="submit" id="ga-update-configuration-submit" class="[ c-btn c-btn--primary ] form-submit-btn" :disabled="configuration.status.saving">
            <svg v-show="!configuration.status.saving" xmlns="http://www.w3.org/2000/svg" width="21.422" height="21.422" viewBox="0 0 21.422 21.422">
                <g fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" transform="translate(.5 .5)">
                    <path d="M4.322 10.211h10.995"></path>
                    <circle cx="10.211" cy="10.211" r="10.211"></circle>
                    <path d="M10.21 5.106l5.106 5.106-5.106 5.106"></path>
                </g>
            </svg>
            <span v-show="configuration.status.saving"><spinner/></span>
            <span v-show="!configuration.status.saving">Configuratie aanpassen</span>
        </button>
    </form>
</template>

<script>
    import { mapActions, mapGetters, mapState } from 'vuex';
    import { required } from 'vuelidate/lib/validators';
    import normalizeFormData from '../../helpers/normalizeFormData';
    import Spinner from '../Spinner.vue';

    export default {
        name: 'SaveFormUpdate',
        components: { Spinner },
        data () {
            return {
                form: {
                    name: '',
                },
                submitted: false,
            };
        },
        props: {
            theme: {
                type: String,
                default: '',
            },
        },
        validations: {
            form: {
                name: { required },
            },
        },
        computed: {
            ...mapGetters('summary', {
                configurationJSON: 'getNormalizedItemsJSON',
                summary: 'getSummaryByIndex',
            }),
            ...mapGetters('prices', {
                total: 'totalRaw',
            }),
            ...mapState({
                account: (state) => state.account,
                configuration: (state) => state.configuration,
            }),
            style () {
                return this.summary('style').find((summary) => summary.id);
            },
        },
        mounted () {
            if (this.configuration.current)
            {
               this.form.name = this.configuration.current.name;
            }
        },
        methods: {
            ...mapActions('configuration', ['update']),
            async handleSubmit (e) {
                this.submitted = true;

                // validate the form
                this.$v.form.$touch();

                // if its still pending or an error is returned do not submit
                if (this.$v.form.$pending || this.$v.form.$error) return;

                // normalize form data
                const data = normalizeFormData(e.target);

                // update
                await this.update(data);
            },
        },
    };
</script>
