<template>
    <div>
        <template v-if="!account.status.loggedIn">
            <tabs>
                <tab title="Registreren en opslaan">
                    <h3 class="save-configuration-title">Creëer een account om uw bedrijfspand configuratie op te slaan.</h3>

                    <register-form v-on:emailExists="emailExists = $event"/>
                </tab>
                <tab title="Inloggen en opslaan">
                    <h3 class="save-configuration-title">Inloggen en opslaan</h3>

                    <login-form/>
                </tab>
            </tabs>
        </template>

        <div v-if="account.status.loggedIn">
            <tabs v-if="configuration.current.name">
                <tab title="Opslaan als huidige configuratie">
                    <save-form-update/>
                </tab>
                <tab title="Opslaan als nieuwe configuratie">
                    <save-form-create/>
                </tab>
            </tabs>

            <template v-else>
                <div class="save-configuration">
                    <h3 class="save-configuration-title">Configuratie opslaan</h3>

                    <save-form-create/>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { Tabs, Tab } from 'vue-slim-tabs';
    import LoginForm from './forms/LoginForm.vue';
    import RegisterForm from './forms/RegisterForm.vue';
    import SaveFormCreate from './forms/SaveFormCreate.vue';
    import SaveFormUpdate from './forms/SaveFormUpdate.vue';

    export default {
        name: 'SaveConfiguration',
        components: {
            Tabs,
            Tab,
            SaveFormCreate,
            SaveFormUpdate,
            RegisterForm,
            LoginForm,
        },
        data () {
            return {
                emailExists: false,
            };
        },
        computed: {
            ...mapState({
                account: (state) => state.account,
                configuration: (state) => state.configuration,
            }),
        },
    };
</script>

<style lang="scss" scoped>
    @import "../assets/scss/essentials";

    .save-configuration-title {
        margin-bottom: rem(20);

        @include media-breakpoint-up(lg) {
            margin-bottom: rem(50);
        }
    }

    .form {
        max-width: 100%;
    }

    .save-configuration,
    .vue-tabpanel {
        padding: 10%;

        @include media-breakpoint-up(lg) {
            padding: 5% 10%;
        }
    }

    .save-configuration {
        @include media-breakpoint-up(up) {
            padding: 7.5% 10%;
        }
    }
</style>
