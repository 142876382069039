import { render, staticRenderFns } from "./SaveConfiguration.vue?vue&type=template&id=757f660b&scoped=true"
import script from "./SaveConfiguration.vue?vue&type=script&lang=js"
export * from "./SaveConfiguration.vue?vue&type=script&lang=js"
import style0 from "./SaveConfiguration.vue?vue&type=style&index=0&id=757f660b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "757f660b",
  null
  
)

export default component.exports